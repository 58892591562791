import axios from 'axios';

function logError(error) {
	console.log(error);
	throw error;
}

class HomePageService {
	getHomePageData(language) {
		return axios
			.post(
				`${process.env.REACT_APP_API_ENDPOINT}/api/singletons/get/HomePage?
		`,
				{
					populate: 2,
					lang: language,
				},
			)
			.then(res => res.data)
			.catch(logError);
	}

	submitQuestion(data) {
		const formData = new FormData();
		formData.set('form[email]', data.email);
		formData.set('form[question]', data.question);

		return axios
			.post(
				`${
					process.env.REACT_APP_API_ENDPOINT
				}/api/forms/submit/questions?token=${process.env.REACT_APP_API_TOKEN}`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}
			)
			.then(res => res)
			.catch(logError);
	}
}

export default new HomePageService();
