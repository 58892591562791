import React from 'react';

import './error-message.scss';

const ErrorMessage = ({ label1, label2 }) => (
	<div className="error-message">
		<p>{label1}</p>
		<p>{label2}</p>
	</div>
);

export default ErrorMessage;
