import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { PrimaryButton } from '../ui-toolkit/buttons.js';
import notImplementedImage from '../../assets/images/not-implemented/not-implemented.png';

import './not-implemented.scss';

const NotImplemented = ({ title }) => (
	<div className="not-implemented-wrapper">
		<div className="not-implemented-content">
			{title && <h1>{title}</h1>}
			<img src={notImplementedImage} alt="Not implemented" />
			<div className="center-xs">
				<p className="title"><FormattedMessage id="not-implemented.title" /></p>
				<p className="message"><FormattedMessage id="not-implemented.message" /></p>
				<a href="/">
					<PrimaryButton>
						<FormattedMessage id="not-implemented.button_label" />
					</PrimaryButton>
				</a>
			</div>
		</div>
	</div>
);

export default injectIntl(NotImplemented);
