class EventDetailsModel {
	id;

	title;

	description;

	date;

	image;

	slug;

	locationLink;

	locationName;

	publicationDate;

	constructor({
		_id = '', title = '', date = '', description = '',
		image = '', slug = '', locationLink = '', locationName = '',
		_created = 0,
	}) {
		this.id = _id;
		this.title = title;
		this.date = date;
		this.description = description;
		this.image = image;
		this.slug = slug;
		this.locationLink = locationLink;
		this.locationName = locationName;
		this.publicationDate = _created * 1000;
	}
}

export default EventDetailsModel;
