import axios from 'axios';

import EventDetailsModel from './event-details.model.js';

function logError(error) {
	console.error(error);
	throw error;
}

class EventsService {
	getPastEvents({ language, limit, skip, date }) {
		return axios
			.post(
				`${process.env.REACT_APP_API_ENDPOINT}/api/collections/get/Events?
			`,
				{
                    lang: language,
                    populate: 2,
                    filter: {
                        date: { $lt: date },
                    },
					sort: {
						date: -1,
					},
					limit,
					skip,
				},
			)
			.then(({ data }) => ({
				data: data.entries.map(entry => new EventDetailsModel(entry)),
				total: data.total,
			}));
    }

    getUpcomingEvents({ language, limit, skip, date }) {
		return axios
			.post(
				`${process.env.REACT_APP_API_ENDPOINT}/api/collections/get/Events?
			`,
				{
                    lang: language,
                    populate: 2,
                    filter: {
                        date: { $gt: date },
                    },
					sort: {
						date: 1,
					},
					limit,
					skip,
				},
			)
			.then(({ data }) => ({
				data: data.entries.map(entry => new EventDetailsModel(entry)),
				total: data.total,
			}));
	}

	getEventDetailsBySlug(language, slug) {
		return axios
			.get(
				`${process.env.REACT_APP_API_ENDPOINT}/api/collections/get/Events?
			lang=${language}&filter[slug]=${slug}&populate=1`,
			)
			.then(({ data }) => {
				if (!data.entries || data.entries.length === 0) {
					return Promise.reject();
				}
				return new EventDetailsModel(data.entries[0]);
			})
			.catch(error => Promise.reject(error));
	}

	getNextRelatedEvent(language, date) {
		return axios
			.post(`${process.env.REACT_APP_API_ENDPOINT}/api/collections/get/Events`, {
				lang: language,
				filter: {
					date: { $lt: date }
				},
				sort: {
					date: -1
				},
				limit: 1
			})
			.then((result) => {
				if (result.data.entries.length === 0) {
					return axios.post(
						`${process.env.REACT_APP_API_ENDPOINT}/api/collections/get/Events`,
						{
							lang: language,
							sort: {
								date: -1
							},
							limit: 1
						}
					);
				}
				return result;
			})
			.then((result) => {
				if (result.data.entries.length === 0) {
					return null;
				}
				return new EventDetailsModel(result.data.entries[0]);
			})
			.catch(logError);
	}
}

export default new EventsService();
