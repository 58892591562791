import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { PrimaryButton } from '../ui-toolkit/buttons.js';

import './info-bubbles.scss';

const InfoBubbles = ({
	sectiontitle,
	title,
	description,
	link,
	btnLabel,
	shuffledCompanies
}) => (
	<div className="row info-bubbles">
		<div className="col-sm-12 col-md-3 img-col img-col-left">
			{shuffledCompanies &&
				shuffledCompanies
					.slice(0, Math.round(shuffledCompanies.length / 2))
					.filter(c => c.image && c.image.path)
					.map(c => (
						<div>
							<img
								alt="alt"
								src={`${process.env.REACT_APP_API_ENDPOINT + c.image.path}`}
							/>
						</div>
					))}
		</div>
		<div className="content-col col-sm-12 col-md-6">
			{sectiontitle &&
				<h5 className="section-title">{sectiontitle}</h5>
			}
			<div className="title">
				<h4 className="title" dangerouslySetInnerHTML={{__html: title}}>
				</h4>
			</div>
			<div className="description">
				<p>{description}</p>
			</div>
			<PrimaryButton link={link}>
				<FormattedMessage id={btnLabel} />
			</PrimaryButton>
		</div>
		<div className="col-sm-12 col-md-3 img-col img-col-right">
			{shuffledCompanies &&
				shuffledCompanies
					.slice(
						Math.round(shuffledCompanies.length / 2),
						shuffledCompanies.length
					)
					.filter(c => c.image && c.image.path)
					.map(c => (
						<div>
							<img
								alt="alt"
								src={`${process.env.REACT_APP_API_ENDPOINT + c.image.path}`}
							/>
						</div>
					))}
		</div>
	</div>
);
export default injectIntl(InfoBubbles);
