import React, { Fragment } from 'react';
// eslint-disable-next-line import/extensions
import { connect } from 'redux-zero/react';
import { FormattedMessage, injectIntl } from 'react-intl';
import cn from 'classnames';
import Carousel from 'nuka-carousel';
import { Field, Formik } from 'formik';
import { Helmet } from 'react-helmet';

import { withLocaleContext } from '../../locales/locale-context.js';
import { prefixPathWithLocale } from '../../locales/locale-paths.js';
import { validateEmail } from '../../shared/utils.js';
import { shuffleArr } from '../../shared/utils.js';
import { actions } from '../../shared/store.js';
import HomePageService from './home.service.js';
import NewsService from '../news-events/news/news.service.js';
import EventsService from '../news-events/events/events.service.js';
import {
	FormTextBox,
	FormTextArea,
} from '../../components/ui-toolkit/form-inputs.js';
import { PrimaryButton } from '../../components/ui-toolkit/buttons.js';
import Slider from '../../components/slider/slider.component.js';
import ErrorMessage from '../../components/error-message/error-message.component.js';
import PageHeader from '../../components/page-header/page-header.component.js';
import NotImplemented from '../../components/not-implemented/not-implemented.js';
import Step from '../../components/step/step.component.js';
import InfoBubbles from '../../components/info-bubbles/info-bubbles.component.js';
import Bullets from '../../components/slider/bullets.component.js';

import icon from '../../assets/icons/slider/selected.svg';
import success from '../../assets/icons/success.svg';

import './home.scss';
import '../../styles/video-player.scss';
import ccj from '../../assets/images/home/ccj.svg';
import NewsDetailsModel from '../news-events/news/news-details.model.js';

const Slide = ({ obj, link }) => (
	<div
		className={`slide ${cn({ noImageSlide: !obj.image.path })}`}
		style={{
			backgroundImage:
			obj.image && obj.image.path
					? `url('${process.env.REACT_APP_API_ENDPOINT + obj.image.path}')`
					: ' ',
		}}
	>
		<div className="opacity" />
		<div className="content center-xs start-md">
			<div className="title">
				<h1>{obj.title}</h1>
			</div>
			<div className="button">
				{!obj.goTo && (
					<PrimaryButton link={link}>
						<FormattedMessage id={`homepage.slider.button_label.${obj instanceof NewsDetailsModel ? 'read_news' : 'view_event'}`} />
					</PrimaryButton>
				)}

				{obj.goTo && (
					<PrimaryButton className="btn btn-primary" href={link} onclick={obj.goTo}>
						<FormattedMessage id="homepage.slider.button_label" />
					</PrimaryButton>
				)}
			</div>
		</div>
	</div>
);

const IconSlider = ({ first, image, link }) => (
	<div className={`icon ${cn({ first })}`}>
		<a href={link} target="_blank" rel="noopener noreferrer">
			<img src={image} alt="" />
		</a>
	</div>
);

const Success = ({ title, description }) => (
	<div className="center-xs success row">
		<div className="col-xs-12 center-xs">
			<img src={success} alt="Success" />
		</div>
		<div className="col-xs-12 col-md-9 center-xs">
			<h3>{title}</h3>
		</div>
		<div className="col-xs-12 col-md-4 center-xs">
			<h6>{description}</h6>
		</div>
	</div>
);

/**
 * Returns true if date is ate least from one month ago
 * @param {[string, number]} field field name to compare
 */
const newsEventFilter = (field) => {
	return (object) => {
		const comparingDate = new Date();
		comparingDate.setHours(0,0,0,0);
		comparingDate.setMonth(comparingDate.getMonth() - 1);

		const newsDate = new Date(object[field]);
		newsDate.setHours(0,0,0,0);

		return comparingDate.getTime() <= newsDate.getTime();
	}
}

const arrangeNewsEvents = (news, upcomingEvents) => {
	const filteredNews = news.filter(newsEventFilter("date"));
	const filteredUpcomingEvents = upcomingEvents.filter(newsEventFilter("publicationDate"));
	const joinedArr = [...filteredNews, ...filteredUpcomingEvents];
	joinedArr.sort((a, b) => a.date - b.date)
	return joinedArr;
}

class HomePage extends React.Component {
	static prefetchData = async language => Promise.all([
		HomePageService.getHomePageData(language),
		NewsService.getNews({ language, limit: 0, slip: 0 }),
		EventsService.getUpcomingEvents({
			language,
			limit: 0,
			skip: 0,
			date: new Date()
		}),
	]);

	state = {
		hasError: false,
		submittedForm: false,
		hasFormError: false,
		videoOpen: false,
	};

	constructor(props) {
		super(props);

		this.nextSlideButtonRef = React.createRef();
		this.carouselRef = React.createRef();
	}

	componentDidMount() {
		const { homePageData, setLoading } = this.props;
		if (!homePageData) {
			this.fetchData();
		} else {
			setLoading(false);
		}
	}

	componentDidUpdate(prevProps) {
		const {
			localeContext: { locale },
		} = this.props;

		if (prevProps.localeContext.locale.value !== locale.value) {
			this.fetchData();
		}
	}

	componentWillUnmount() {
		clearTimeout(this.sliderTimer);
	}

	handleBeforeSlide = () => {
		if (!this.nextSlideButtonRef.current) return;

		this.nextSlideButtonRef.current.classList.add('reset');
		this.sliderTimer = setTimeout(() => {
			this.nextSlideButtonRef.current.classList.remove('reset');
		}, 1000);
	};

	handleSlide = (index) => {
		if (this.carouselRef) {
			this.carouselRef.current.goToSlide(index);
		}
	};

	slideToSteps = () => {
		window.scrollTo({
			top: 800,
			behavior: 'smooth',
		});
		return null;
	};

	toggleVideo = () => {
		if(!this.state.videoOpen) {
			document.getElementsByTagName('body')[0].className='full-screen';
		} else {
			document.getElementsByTagName('body')[0].className='';
		}
		this.setState(prevState => ({ videoOpen: !prevState.videoOpen }));
	};

	fetchData() {
		const {
			setLoading,
			setHomePageData,
			setHomeNewsEventsData,
			localeContext: { locale },
		} = this.props;
		setLoading(true);

		HomePage.prefetchData(locale.value)
			.then((data) => {
				setHomePageData(data[0]);
				setHomeNewsEventsData(arrangeNewsEvents(data[1].data, data[2].data));
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);

				this.setState({
					hasError: true,
				});
			});
	}

	render() {
		const {
			hasError, submittedForm, hasFormError, videoOpen,
		} = this.state;
		const {
			homePageData,
			homeNewsEventsData,
			intl,
			localeContext: { locale },
		} = this.props;

		if (hasError || !homePageData || !homeNewsEventsData) {
			return <NotImplemented />;
		}

		const {
			slider,
			slider2,
			testimonials,
			steps,
			companiesInfo,
		} = homePageData;

		if (!this.shuffledCompanies) {
			this.shuffledCompanies = shuffleArr(companiesInfo.companiesToDisplay);
			if (this.shuffledCompanies.length > 10) {
				this.shuffledCompanies.length = 10;
			}
		}

		return (
			<Fragment>
				<div id="home-page">
					<Helmet>
						<title>
							{intl.formatMessage({
								id: 'navigation.home_page',
							})}
						</title>
					</Helmet>
					<section className="slider ">
						<div className="container container-fluid">
							<div className="row slider-section">
								<div className="col-xs-12">
									<Carousel
										ref={this.carouselRef}
										swiping={!!homeNewsEventsData.length}
										dragging={!!homeNewsEventsData.length}
										wrapAround
										pauseOnHover={false}
										autoplay={slider && slider.length + homeNewsEventsData.length > 1}
										autoplayInterval={8000}
										speed={1000}
										beforeSlide={this.handleBeforeSlide}
										renderCenterLeftControls={({ previousSlide }) => {
											if (!slider || slider.length + homeNewsEventsData.length <= 1) return null;
											return (
												<button className="left-arrow" onClick={previousSlide} />
											);
										}}
										renderCenterRightControls={({ nextSlide }) => {
											if (!slider || slider.length + homeNewsEventsData.length <= 1) return null;

											return (
												<button
													className="right-arrow"
													onClick={nextSlide}
													ref={this.nextSlideButtonRef}
												>
													<svg
														className="circle-timer"
														width="42px"
														height="42px"
													>
														<circle cx="21" cy="21" r="19" />
													</svg>
												</button>
											);
										}}
										renderBottomCenterControls={() => (
											<Bullets
												amount={slider.length + homeNewsEventsData.length}
												selected={
													this.carouselRef.current
														? this.carouselRef.current.state.currentSlide
														: null
												}
												handleToSlide={this.handleSlide}
											/>
										)}
									>
										{slider
										&& slider.map(slide => (
											<Slide
												key={slide.value.title}
												obj={{
													title: slide.value.title,
													image: slide.value.image,
													goTo: this.slideToSteps
												}}
												link={prefixPathWithLocale(slide.value.link, locale)}
											/>
										))}
										{homeNewsEventsData
										&& homeNewsEventsData.map(obj => (
											<Slide
												obj={obj}
												link={prefixPathWithLocale(
													`${obj instanceof NewsDetailsModel ? '/news/' : '/events/'}${obj.slug}`,
													locale,
												)}
											/>
										))}
									</Carousel>
								</div>
								<div className="icons">
									<IconSlider
										image={ccj}
										link="http://www.centrocienciajunior.com"
									/>
								</div>
							</div>
						</div>
					</section>
					<section className="icons-mobile center-xs">
						<div className="icons">
							<IconSlider image={ccj} link="http://www.centrocienciajunior.com" />
						</div>
					</section>
					<section id="steps" className="description">
						<div className="container container-fluid">
							<div className="row center-xs">
								<div className="col-xs-12 center-xs icon">
									<img src={icon} alt="biocant" />
								</div>
								<div className="col-xs-12 col-md-8 center-xs title">
									<div className="title">
										<h3>
											<FormattedMessage id="homepage.title1" />
											<span className="bold">
												<FormattedMessage id="homepage.title2" />
											</span>
										</h3>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section id="steps" className="steps">
						<div className="container container-fluid">
							{steps
							&& steps.map((step, index) => (
								<Step
									key={step.value.title}
									title={step.value.title}
									description={step.value.description}
									btnLabel="homepage.sectors.button_label"
									image={step.value.image}
									number={index + 1}
									link={prefixPathWithLocale(step.value.link, locale)}
									imageLink={step.value.imageLink}
								/>
							))}
						</div>
					</section>
					<section className="our-companies">
						<div className="container container-fluid">
							{companiesInfo && this.shuffledCompanies && (
								<InfoBubbles
									title={companiesInfo.title}
									description={companiesInfo.description}
									link={prefixPathWithLocale(companiesInfo.link, locale)}
									btnLabel={companiesInfo.btnLabel}
									shuffledCompanies={this.shuffledCompanies}
								/>
							)}
						</div>
					</section>
					{testimonials && (
						<section className="testimonials">
							<div className="container container-fluid">
								<div className="row">
									<div className="col-xs-12 col-md-6">
										<div className="row">
											<div className="col-xs-12 col-md-12">
												<div className="button-section">
													<PageHeader
														title={intl.formatMessage({
															id: 'homepage.testimonials',
														})}
													/>
												</div>
											</div>
											<div className="col-xs-12 col-md-12">
												<div className="quote">
													<h4>{testimonials.quote}</h4>
												</div>
											</div>
											<div className="col-xs-12 col-md-12">
												<div className="author">
													<h5>{testimonials.author}</h5>
												</div>
											</div>
											<div className="col-xs-12 col-md-12">
												<div className="role">
													<h6>{testimonials.role}</h6>
												</div>
											</div>
										</div>
									</div>
									<div className="col-xs-12 col-md-5 video-image">
										<div className="play-button" onClick={this.toggleVideo} />
									</div>
								</div>
							</div>
						</section>
					)}
					<section className="people-say">
						<div className="container container-fluid">
							<div className="people-say-container">
								<div className="title center-xs">
									<h4>
										<FormattedMessage id="homepage.people_say" />
									</h4>
								</div>
								<div className="slider">
									<Slider slides={slider2} />
								</div>
							</div>
						</div>
					</section>
					<section className="question">
						<div className="container container-fluid">
							{!submittedForm ? (
								<div className="title center-xs row form">
									<div className="col-xs-12 center-xs">
										<h2>
											<FormattedMessage id="homepage.question.title" />
										</h2>
									</div>
									<div className="col-xs-12 col-md-6">
										<h6>
											<FormattedMessage id="homepage.question.description1" />
											<FormattedMessage id="homepage.question.description2" />
										</h6>
									</div>
									<div className="col-xs-12 col-md-8 center-xs">
										<div className="container">
											<Formik
												enableReinitialize
												initialValues={{
													email: '',
													question: '',
												}}
												onSubmit={(values, { setSubmitting }) => {
													setSubmitting(true);
													this.setState({ hasFormError: false });
													HomePageService.submitQuestion(values)
														.then(() => {
															setSubmitting(false);
															this.setState({
																submittedForm: true,
																hasFormError: false,
															});
														})
														.catch(() => {
															setSubmitting(false);
															this.setState({
																hasFormError: true,
															});
														});
												}}
											>
												{({
													errors,
													dirty,
													touched,
													handleSubmit,
													isSubmitting,
													isValid,
												}) => (
													<form onSubmit={handleSubmit} noValidate>
														<div className="row center-xs">
															<div className="col-xs-12 col-md-7 center-xs">
																<Field
																	component={FormTextBox}
																	type="email"
																	name="email"
																	placeholder={intl.formatMessage({
																		id: 'homepage.form.email_label',
																	})}
																	fullWidth
																	error={errors.email && touched.email}
																	validate={(value) => {
																		let error = false;
																		if (!validateEmail(value)) {
																			error = intl.formatMessage({
																				id: 'errors.invalid_email',
																			});
																		}
																		if (!value) {
																			error = intl.formatMessage({
																				id: 'errors.required',
																			});
																		}
																		return error;
																	}}
																	validationMessage={errors.email}
																	required
																/>
															</div>
															<div className="col-xs-12 col-md-7 center-xs">
																<Field
																	component={FormTextArea}
																	name="question"
																	placeholder={intl.formatMessage({
																		id: 'homepage.form.question_label',
																	})}
																	rows={7}
																	fullWidth
																	error={errors.question && touched.question}
																	validate={(value) => {
																		let error = false;
																		if (value.length < 10) {
																			error = intl.formatMessage({
																				id: 'errors.too_short',
																			});
																		}
																		if (!value) {
																			error = intl.formatMessage({
																				id: 'errors.required',
																			});
																		}

																		return error;
																	}}
																	validationMessage={errors.question}
																	required
																/>
															</div>
															<div className="col-xs-12 col-md-7 center-xs">
																<PrimaryButton
																	type="submit"
																	disabled={!isValid || !dirty || isSubmitting}
																	extraClasses="full-width"
																>
																	<FormattedMessage id="homepage.form.send_message" />
																</PrimaryButton>
															</div>
															{hasFormError && (
																<div className="col-xs-12 col-md-7 center-xs">
																	<ErrorMessage
																		label1={
																			<FormattedMessage id="error.form.submitting" />
																		}
																		label2={
																			<FormattedMessage id="error.form.submitting.try_again" />
																		}
																	/>
																</div>
															)}
														</div>
													</form>
												)}
											</Formik>
										</div>
									</div>
								</div>
							) : (
								<Success
									title={intl.formatMessage({
										id: 'homepage.form.success.title',
									})}
									description={intl.formatMessage({
										id: 'homepage.form.success.description',
									})}
								/>
							)}
						</div>
					</section>
				</div>
				{testimonials.testimonialVideo && (
					<div className={`video-player ${cn({ active: videoOpen })} `}>
						<div className="video-wrapper">
							<div
								className="close-video-player"
								onClick={this.toggleVideo}
							/>
							<div className="video">
								{testimonials.testimonialVideo && videoOpen && (
									<video controls autoPlay="autoplay">
										<source
											src={`${process.env.REACT_APP_API_ENDPOINT}/${testimonials.testimonialVideo}`}
											type="video/mp4"
										/>
										{'Your browser does not support HTML5 video.'}
									</video>
								)}
							</div>
						</div>
					</div>
				)}
			</Fragment>
		);
	}
}

const mapToProps = ({ homePageData, homeNewsEventsData }) => ({
	homePageData,
	homeNewsEventsData,
});

export default withLocaleContext(
	injectIntl(connect(mapToProps, actions)(HomePage)),
);

export { HomePage };
