import React, { Component, Fragment } from 'react';
import { FormattedDate, injectIntl } from 'react-intl';
import Swipe from 'react-easy-swipe';

import './slider.scss';

class SliderComponent extends Component {
	static defaultProps = {
		unity: 60,
		margin: 20,
		slides: [],
		hasPagination: false,
	};

	constructor(props) {
		super(props);

		this.state = {
			position: Math.round(props.slides.length / 2) - 1,
			swipeable: false,
			isAnimating: false,
		};
	}

	onSwipeLeft = () => {
		const { slides } = this.props;
		const { isAnimating, position, swipeable } = this.state;

		if (!swipeable) return;

		const max = slides.length - 1;

		if (isAnimating || (max <= 2 && position === max)) {
			return;
		}

		this.moveTo(position + 1);
	};

	onSwipeRight = () => {
		const { slides } = this.props;
		const { isAnimating, position, swipeable } = this.state;

		if (!swipeable) return;

		const max = slides.length - 1;

		if (isAnimating || (max <= 2 && position === 0)) {
			return;
		}

		this.moveTo(position - 1);
	};

	onSwipeMove = (position) => {
		const { slides } = this.props;

		this.setState({
			swipeable: slides.length > 1 && Math.abs(position.x) > 40,
		});

		return Math.abs(position.x) > 40;
	};

	moveTo(position) {
		const {
			slides: { length },
		} = this.props;

		if (position < 0 || position > length - 1) {
			return;
		}

		this.setState({
			position,
		});
	}

	render() {
		let { slides } = this.props;
		const { position } = this.state;

		const lineClamp = {
			WebkitBoxOrient: 'vertical',
		};

		if (!slides) return null;

		slides = slides.slice(0, 5);

		return (
			<div className="section slider-2">
				{slides
					&& slides.map((item, index) => (
						<input
							type="radio"
							name="slider"
							key={index}
							id={`slide-${index + 1}`}
							className="slider-2__radio"
							checked={index === position}
							readOnly
						/>
					))}
				<Swipe
					className="slider-2__holder"
					allowMouseEvents
					onSwipeLeft={this.onSwipeLeft}
					onSwipeRight={this.onSwipeRight}
					onSwipeMove={this.onSwipeMove}
				>
					{slides
						&& slides.map((item, index) => (
							<label
								htmlFor={`slide-${index + 1}`}
								key={index}
								className={`slider-2__item slider-2__item--${index + 1} card`}
								onClick={() => this.moveTo(index)}
							>
								<h6 className="description" style={lineClamp}>
									{item.value.description}
								</h6>
								<p className="author">
									{item.value.author}
									{item.value.link
									&& <a href={item.value.link} target="_blank" rel="noopener noreferrer">&nbsp;</a>
									}
								</p>
								<p className="date">
									{item.value.date && (
										<FormattedDate
											value={item.value.date}
											month="short"
											day="numeric"
											year="numeric"
										/>
									)}
									{item.value.local && item.value.date && <span>, </span>}
									{item.value.local && <Fragment>{item.value.local}</Fragment> }
								</p>
							</label>
						))}
				</Swipe>

				<div className="bullets">
					{slides
						&& slides.map((item, index) => (
							<button
								key={index}
								className={`bullets__item bullets__item--${index + 1}`}
								onClick={() => this.moveTo(index)}
							/>
						))}
				</div>
			</div>
		);
	}
}

export default injectIntl(SliderComponent);
