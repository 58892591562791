import React from 'react';
import cn from 'classnames';

import './inputs.scss';

const TextBox = ({
	type,
	name,
	ariaLabel,
	placeholder,
	value,
	onChange,
	required,
	message,
	error,
	maxLength,
	validationMessage,
	handleChange,
	handleBlur,
	fullWidth
}) => (
	<div
		className={cn('text-box', {
			error: error || false,
			'full-width': fullWidth
		})}
	>
		<div className="text-box-input-wrapper">
			<input
				className="text-box-input"
				type={type}
				name={name}
				aria-label={ariaLabel}
				placeholder={placeholder}
				value={value}
				onChange={onChange || handleChange}
				onBlur={handleBlur}
				required={required}
				maxLength={maxLength}
			/>
		</div>

		{error && validationMessage && (
			<div className="text-box-message">{validationMessage}</div>
		)}

		{!error && message && <div className="text-box-message">{message}</div>}
	</div>
);

const TextArea = ({
	name,
	ariaLabel,
	placeholder,
	value,
	onChange,
	required,
	maxLength,
	message,
	success,
	error,
	validationMessage,
	handleChange,
	handleBlur,
	rows,
	fullWidth
}) => (
	<div
		className={cn('text-area', {
			error: error || false,
			'full-width': fullWidth
		})}
	>
		<textarea
			className="text-area-input"
			name={name}
			aria-label={ariaLabel}
			placeholder={placeholder}
			value={value}
			onChange={onChange || handleChange}
			onBlur={handleBlur}
			required={required}
			maxLength={maxLength}
			rows={rows}
		/>

		{error && validationMessage && (
			<div className="text-area-message">{validationMessage}</div>
		)}

		{!error && message && <div className="text-area-message">{message}</div>}
	</div>
);

export { TextBox, TextArea };
