import axios from 'axios';

import NewsDetailsModel from './news-details.model.js';

function logError(error) {
	console.error(error);
	throw error;
}

class NewsService {
	getNews({ language, limit, skip }) {
		return axios
			.post(
				`${process.env.REACT_APP_API_ENDPOINT}/api/collections/get/News?
			`,
				{
					lang: language,
					sort: {
						date: -1
					},
					limit,
					skip
				}
			)
			.then(({ data }) => ({
				data: data.entries.map(entry => new NewsDetailsModel(entry)),
				total: data.total
			}));
	}

	getNextRelatedNews(language, date) {
		return axios
			.post(`${process.env.REACT_APP_API_ENDPOINT}/api/collections/get/News`, {
				lang: language,
				filter: {
					date: { $lt: date }
				},
				sort: {
					date: -1
				},
				limit: 1
			})
			.then((result) => {
				if (result.data.entries.length === 0) {
					return axios.post(
						`${process.env.REACT_APP_API_ENDPOINT}/api/collections/get/News`,
						{
							lang: language,
							sort: {
								date: -1
							},
							limit: 1
						}
					);
				}
				return result;
			})
			.then((result) => {
				if (result.data.entries.length === 0) {
					return null;
				}
				return new NewsDetailsModel(result.data.entries[0]);
			})
			.catch(logError);
	}

	getNewsDetailsBySlug(language, slug) {
		return axios
			.get(
				`${process.env.REACT_APP_API_ENDPOINT}/api/collections/get/News?
			lang=${language}&filter[slug]=${slug}&populate=1`
			)
			.then(({ data }) => {
				if (!data.entries || data.entries.length === 0) {
					return Promise.reject();
				}
				return new NewsDetailsModel(data.entries[0]);
			})
			.catch(error => Promise.reject(error));
	}
}

export default new NewsService();
