import cn from 'classnames';
import React from 'react';

const Bullets = ({ amount, selected, handleToSlide }) => {
	if (selected !== null) {
		let i = 0;
		const points = [];
		while (i < amount) {
			points.push(i);
			i += 1;
		}
		return (
			<div className="bullets">
				<ul>
					{points
					&& points.map((item, index) => (
						<li
							key={item}
							onClick={() => handleToSlide(index)}
							className={cn({ active: item === selected })}
						>
							<span>&nbsp;</span>
						</li>
					))}
				</ul>
			</div>
		);
	}
	return null;
};

export default Bullets;
