class NewsDetailsModel {
	id;

	title;

	description;

	date;

	image;

	article;

	slug;

	constructor({
		_id = '', title = '', date = '', description = '',
		image = '', article = '', slug = '',
		_created = 0,
	}) {
		this.id = _id;
		this.title = title;
		this.date = date;
		this.description = description;
		this.image = image;
		this.article = article;
		this.slug = slug;
		this.publicationDate = _created * 1000;
	}
}

export default NewsDetailsModel;
