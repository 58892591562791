import React from 'react';

import './page-header.scss';

const pageHeader = ({ title }) => (
	<div id="page-header">
		<h6>{title}</h6>
	</div>
);

export default pageHeader;
