import React from 'react';
import cn from 'classnames';
import { PrimaryButton } from '../ui-toolkit/buttons';
import { FormattedMessage } from 'react-intl';

import './step.scss';

const Step = ({
	title,
	description,
	btnLabel,
	image,
	number,
	link,
	imageLink,
}) => (
	<div className={cn('row step center-xs middle-xs start-md', { reverse: number % 2 !== 0 })}>
		<div
			className={cn('col-xs-12 col-order col-md-5 last-xs')}
		>
			<div className="content">
				<div className="title">
					<div className="title-label">
						<h4>{title}</h4>
					</div>
				</div>
				<div className="description">
					<p>{description}</p>
					{btnLabel && (
						<PrimaryButton link={link}>
							<FormattedMessage id={btnLabel} />
						</PrimaryButton>
					)}
				</div>
			</div>
		</div>
		<div
			className={cn('col-xs-12 col-md-6 center-xs image', {
				odd: number % 2 !== 0,
			})}
		>
			{imageLink ? (
				<a href={imageLink} target="_blank" rel="noopener noreferrer">
					{image && image.path && (
						<img
							src={`${process.env.REACT_APP_API_ENDPOINT + image.path}`}
							alt={title}
						/>
					)}
				</a>
			) : (
				image &&
				image.path && (
					<img
						src={`${process.env.REACT_APP_API_ENDPOINT + image.path}`}
						alt={title}
					/>
				)
			)}
		</div>
	</div>
);

export default Step;
